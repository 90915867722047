<template>
  <div class="d-flex align-center text-caption">
    <v-icon :class="{'mr-2': dense, 'mr-6': !dense}">
      {{ icon }}
    </v-icon>
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTextWithIcon',
  props: {
    dense: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>
