<template>
  <v-card
    flat
    color="cassiopee-purple"
    dark
    width="315"
    class="d-flex flex-column align-center justify-center pt-3"
  >
    <div class="mb-5">
      <slot name="prepend" />
    </div>
    <div class="mb-5 big-number">
      <slot />
    </div>
    <BaseButton
      color="white"
      class="cassiopee-purple--text"
      right
      x-small
      icon="$arrowRight"
      icon-color="cassiopee-purple"
      :to="to"
    >
      {{ action }}
    </BaseButton>
  </v-card>
</template>

<script>
import BaseButton from './BaseButton.vue';

export default {
  name: 'BaseNumberCard',
  components: {
    BaseButton,
  },
  props: {
    action: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.big-number {
  font-size: 60px;
}
</style>