<template>
  <v-sheet class="fill-height pt-12 px-16" color="cassiopee-grey lighten-1">
    <div class="d-flex align-center justify-end mb-8">
      <BaseButton
        right
        x-small
        icon="$arrowRight"
        outlined
        class="mr-9"
        to="/profile/person"
      >
        Continuer
      </BaseButton>
      <BaseButton
        right
        x-small
        icon="$arrowRight"
        to="/results"
        :disabled="formIncomplete"
      >
        Voir les résultats
      </BaseButton>
    </div>
    <FormAlert
      v-if="formIncomplete"
      message="Attention. Vous n’avez pas terminé de remplir toutes les données du formulaire pour obtenir les résultats"
      class="mb-6"
    />
    <div class="d-flex align-center justify-center mb-14">
      <BaseTextWithIcon dense icon="$circleCheck" class="mr-10">
        Informations valides
      </BaseTextWithIcon>
      <BaseTextWithIcon dense icon="$circleWarning" class="mr-10">
        Informations incomplètes
      </BaseTextWithIcon>
      <BaseTextWithIcon dense icon="$circleCross">
        Informations indispensables
      </BaseTextWithIcon>
    </div>
    <div>
      <FormStepTitle
        class="mb-6"
        title="Étape 01 - Profil"
        icon="$stepOnePurple"
      />
      <div class="d-flex mb-11">
        <v-card elevation="1" class="flex-1 mr-4 pa-10">
          <BaseTextWithIcon
            class="mb-5"
            :icon="sectionStatusIcon(dashboard.profil.personne)"
          >
            A. Personne
          </BaseTextWithIcon>
          <BaseTextWithIcon
            class="mb-5"
            :icon="sectionStatusIcon(dashboard.profil.entite)"
          >
            B. Établissement
          </BaseTextWithIcon>
          <BaseTextWithIcon
            class="mb-5"
            :icon="sectionStatusIcon(dashboard.profil.ratios_internes)"
          >
            C. Compléments
          </BaseTextWithIcon>
          <BaseTextWithIcon
            class="mb-5"
            :icon="sectionStatusIcon(dashboard.profil.complement)"
          >
            D. Ratios internes
          </BaseTextWithIcon>
        </v-card>
        <BaseNumberCard to="/profile/person" action="Terminer de remplir">
          <template #prepend>
            Taux de remplissage de
          </template>
          {{ Math.round(dashboard.profil.percentage * 100) }}%
        </BaseNumberCard>
      </div>

      <FormStepTitle
        class="mb-6"
        title="Étape 02 - Ressources"
        icon="$stepTwoPurple"
      />
      <div class="d-flex mb-11">
        <v-card elevation="1" class="flex-1 mr-4 pa-10">
          <BaseTextWithIcon
            class="mb-5"
            :icon="
              sectionStatusIcon(
                dashboard.ressources.appreciation_hebergement_actuel_FIELDS,
              )
            "
          >
            A. Element d'appréciations de l'hébergement actuel
          </BaseTextWithIcon>
          <BaseTextWithIcon
            class="mb-5"
            :icon="sectionStatusIcon(dashboard.ressources.hebergement)"
          >
            B. Hébergement
          </BaseTextWithIcon>
          <BaseTextWithIcon
            class="mb-5"
            :icon="sectionStatusIcon(dashboard.ressources.definition_local_it)"
          >
            C. Définition du loal IT
          </BaseTextWithIcon>
        </v-card>
        <BaseNumberCard
          to="/ressources/current-host"
          action="Terminer de remplir"
        >
          <template #prepend>
            Taux de remplissage de
          </template>
          {{ Math.round(dashboard.ressources.percentage * 100) }}%
        </BaseNumberCard>
      </div>
      <FormStepTitle
        class="mb-6"
        title="Étape 03 - Besoins"
        icon="$stepThreePurple"
      />
      <div class="d-flex mb-11">
        <v-card elevation="1" class="flex-1 mr-4 pa-10">
          <BaseTextWithIcon
            class="mb-5"
            :icon="sectionStatusIcon(dashboard.besoins.besoin_hebergement)"
          >
            A. Besoin en référentiels de sécurité pour votre hébergement
          </BaseTextWithIcon>
          <BaseTextWithIcon
            class="mb-5"
            :icon="sectionStatusIcon(dashboard.besoins.vue_macro)"
          >
            B. Vue macro du besoin d'hébergement
          </BaseTextWithIcon>
          <BaseTextWithIcon
            class="mb-5"
            :icon="sectionStatusIcon(dashboard.besoins.datacenter_mutualise)"
          >
            C. Le datacenter mutualisé
          </BaseTextWithIcon>
        </v-card>
        <BaseNumberCard
          to="/needs/security-referential"
          action="Terminer de remplir"
        >
          <template #prepend>
            Taux de remplissage de
          </template>
          {{ Math.round(dashboard.besoins.percentage * 100) }}%
        </BaseNumberCard>
      </div>
      <FormStepTitle
        class="mb-6"
        title="Étape 04 - Stratégie"
        icon="$stepFourPurple"
      />
      <div class="d-flex mb-11">
        <v-card elevation="1" class="flex-1 mr-4 pa-10">
          <BaseTextWithIcon
            class="mb-5"
            :icon="sectionStatusIcon(dashboard.strategie.strategie_5_ans)"
          >
            A. Stratégie à 5 ans
          </BaseTextWithIcon>
          <BaseTextWithIcon
            class="mb-5"
            :icon="sectionStatusIcon(dashboard.strategie.impact_hebergement)"
          >
            B. Impact sur l'hébergement
          </BaseTextWithIcon>
        </v-card>
        <BaseNumberCard to="/strategy/impact" action="Terminer de remplir">
          <template #prepend>
            Taux de remplissage de
          </template>
          {{ Math.round(dashboard.strategie.percentage * 100) }}%
        </BaseNumberCard>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseButton from '../../components/BaseButton.vue';
import BaseNumberCard from '../../components/BaseNumberCard.vue';
import BaseTextWithIcon from '../../components/BaseTextWithIcon.vue';
import FormAlert from '../../components/FormAlert.vue';
import FormStepTitle from '../../components/FormStepTitle.vue';

export default {
  name: 'Dashboard',
  components: {
    BaseButton,
    FormAlert,
    BaseTextWithIcon,
    FormStepTitle,
    BaseNumberCard,
  },
  computed: {
    ...mapState('stepper', ['horizontalSteps', 'currentStepPath']),
    ...mapState('form', ['dashboard']),
    ...mapGetters('form', ['formIncomplete']),
  },
  created() {
    this.fetchDashboard();
    this.fetchMe();
  },
  methods: {
    ...mapActions('form', ['fetchDashboard']),
    ...mapActions('authentication', ['fetchMe']),
    sectionStatusIcon(status) {
      if (status === 'Informations valides') {
        return '$circleCheck';
      } else if (status === 'Informations incomplètes') {
        return '$circleWarning';
      } else {
        return '$circleCross';
      }
    },
  },
};
</script>
