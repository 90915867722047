<template>
  <v-alert
    :color="color"
    text
    class="text-center py-5"
    :class="{ 'content__centered' : centered }"
  >
    <slot />
    <v-icon 
      v-if="icon" 
      class="mr-5"
    >
      {{ icon }}
    </v-icon>
    {{ message }}
  </v-alert>
</template>

<script>
export default {
  name: "FormAlert",
  props: {
    message: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "cassiopee-red"
    },
    centered: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.v-alert__content {
  font-size: 14px;
}
.content__centered > .v-alert__wrapper > .v-alert__content{
  max-width: 515px !important;
  margin: 0 auto;
}
</style>
